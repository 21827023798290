import { Card, Col, Container, Nav, Row, Tab } from "react-bootstrap"
import { Link, withI18next } from "gatsby-plugin-i18next"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { I18n } from "react-i18next"
import Img from "gatsby-image"
import Layout from "../components/layout"
import React from "react"
import SEO from "../components/seo"
import { faCubes } from "@fortawesome/free-solid-svg-icons"
import { graphql } from "gatsby"

class Associations extends React.Component {
  render() {
    const { images, myagmar_ochir } = this.props.data
    return (
      <I18n>
        {t => (
          <Layout pageContext={this.props.pageContext}>
            <SEO title={t("associations")} />
            <div className="section bg-dark-blue mt-4">
              <Container className="height-md-30">
                <Row>
                  <Col md="8" lg="7" xl="6">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb p-0 bg-dark-blue bg-transparent">
                        <li className="breadcrumb-item">
                          <Link to="/" className="text-white">
                            {t("home")}
                          </Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/associations" className="text-white">
                            {t("associations")}
                          </Link>
                        </li>
                      </ol>
                    </nav>
                  </Col>
                  <Col md="12">
                    <h1 className="display-5 text-white">
                      {t("associations")} <FontAwesomeIcon icon={faCubes} />
                    </h1>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="content">
              <div className="section bg-white py-2 mb-5">
                <Container>
                  <h4 className="text-center">{t("founder_statement")}</h4>
                </Container>
              </div>
              <hr />
              <div className="section pt-4 pb-4">
                <Container>
                  <h2>{t("board_members")}</h2>
                  <Row className="team team-list">
                    <Col sm="12" md="6" lg="4" className="d-flex">
                      <Card className="m-lg-4">
                        <div className="image-frame">
                          <div className="image-wrapper">
                            <Img
                              className="card-img-top"
                              fluid={
                                images.nodes
                                  .filter(i => i.name === "Erdene")
                                  .pop().childImageSharp.fluid
                              }
                              alt={t("r_erdene")}
                            />
                          </div>
                        </div>
                        <Card.Body>
                          <Card.Title>{t("r_erdene")}</Card.Title>
                          <Card.Text>{t("r_erdene_desc")}</Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col sm="12" md="6" lg="4" className="d-flex">
                      <Card className="m-lg-4">
                        <div className="image-frame">
                          <div className="image-wrapper">
                            <Img
                              className="card-img-top"
                              fluid={
                                images.nodes
                                  .filter(i => i.name === "Manaljav")
                                  .pop().childImageSharp.fluid
                              }
                              alt={t("g_manaljav")}
                            />
                          </div>
                        </div>
                        <Card.Body>
                          <Card.Title>{t("g_manaljav")}</Card.Title>
                          <Card.Text>{t("g_manaljav_desc")}</Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col sm="12" md="6" lg="4" className="d-flex">
                      <Card className="m-lg-4">
                        <div className="image-frame">
                          <div className="image-wrapper">
                            <Img
                              className="card-img-top"
                              fluid={
                                images.nodes
                                  .filter(i => i.name === "Altangaya")
                                  .pop().childImageSharp.fluid
                              }
                              alt={t("ch_altangaya")}
                            />
                          </div>
                        </div>
                        <Card.Body>
                          <Card.Title>{t("ch_altangaya")}</Card.Title>
                          <Card.Text>{t("ch_altangaya_desc")}</Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col sm="12" md="6" lg="4" className="d-flex">
                      <Card className="m-lg-4">
                        <div className="image-frame">
                          <div className="image-wrapper">
                            <Img
                              className="card-img-top"
                              fluid={
                                images.nodes
                                  .filter(i => i.name === "Altantuya")
                                  .pop().childImageSharp.fluid
                              }
                              alt={t("ts_altantuya")}
                            />
                          </div>
                        </div>
                        <Card.Body>
                          <Card.Title>{t("ts_altantuya")}</Card.Title>
                          <Card.Text>{t("ts_altantuya_desc")}</Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col sm="12" md="6" lg="4" className="d-flex">
                      <Card className="m-lg-4">
                        <div className="image-frame">
                          <div className="image-wrapper">
                            <Img
                              className="card-img-top"
                              fluid={
                                images.nodes
                                  .filter(i => i.name === "Enkhtuya")
                                  .pop().childImageSharp.fluid
                              }
                              alt={t("s_enkhtuya")}
                            />
                          </div>
                        </div>
                        <Card.Body>
                          <Card.Title>{t("s_enkhtuya")}</Card.Title>
                          <Card.Text>{t("s_enkhtuya_desc")}</Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col sm="12" md="6" lg="4" className="d-flex">
                      <Card className="m-lg-4">
                        <div className="image-frame">
                          <div className="image-wrapper">
                            <Img
                              className="card-img-top"
                              fluid={myagmar_ochir.childImageSharp.fluid}
                              alt={t("ts_myagmarochir")}
                            />
                          </div>
                        </div>
                        <Card.Body>
                          <Card.Title>{t("ts_myagmarochir")}</Card.Title>
                          <Card.Text>{t("ts_myagmarochir_desc")}</Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className="section pt-4 pb-4">
                <Container>
                  <h2>{t("founding_associations")}</h2>
                  <h3>{t("founding_associations_desc")}</h3>
                  <Tab.Container defaultActiveKey="cumcc">
                    <div className="tab-container">
                      <Nav fill className="tab-nav">
                        <Nav.Item>
                          <Nav.Link eventKey="cumcc">{t("cumcc")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="cumic">{t("cumic")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="namac">{t("namac")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="umpsc">{t("umpsc")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="aphc">{t("aphc")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="moccu">{t("moccu")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="mctic">{t("mctic")}</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="cumcc">
                          <h4>{t("cumcc_title")}</h4>
                          <p>{t("cumcc_desc")}</p>
                          <p>{t("phone")} +976-7000-0205</p>
                          <p>{t("email")} consumercoop@mongolia.coop</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="cumic">
                          <h4>{t("cumic_title")}</h4>
                          <p>{t("cumic_desc")}</p>
                          <p>{t("phone")} +976-11-310-951, +976-9909-7540</p>
                          <p>{t("fax")} +976-11-310-951</p>
                          <p>{t("email")} Cumic2005@gmail.com</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="namac">
                          <h4>{t("namac_title")}</h4>
                          <p>{t("namac_desc")}</p>
                          <p>{t("email")} info@namac.coop</p>
                          <a
                            href="https://www.facebook.com/National-Association-of-Mongolian-Agricultural-Cooperatives-1745647709000995/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            FB: National Association of Mongolian Agricultural
                            Cooperatives
                          </a>
                        </Tab.Pane>
                        <Tab.Pane eventKey="umpsc">
                          <h4>{t("umpsc_title")}</h4>
                          <p>{t("umpsc_desc")}</p>
                          <p>{t("phone")} +976-11-327-583</p>
                          <p>{t("fax")} +976-11-328-446</p>
                          <p>{t("email")} umpscoop@hotmail.com</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="aphc">
                          <h4>{t("aphc_title")}</h4>
                          <p>{t("aphc_desc")}</p>
                          <p>{t("phone")} +976-9911-2361</p>
                          <p>{t("email")} erdene.mitgate@gmail.com</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="moccu">
                          <h4>{t("moccu_title")}</h4>
                          <p>{t("moccu_desc")}</p>
                          <p>{t("phone")} +976-11-321-093</p>
                          <p>{t("fax")} +976-11-321-093</p>
                          <p>{t("email")} moccu321093@gmail.com</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="mctic">
                          <h4>
                            <Link to="/mctic-home">{t("mctic_desc")}</Link>
                          </h4>
                          <p>{t("phone")} +976-11-350-232</p>
                          <p>{t("email")} mctic@mongolia.coop</p>
                          <a
                            href="https://www.facebook.com/cooperativesmongolia/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            FB: COOPMongolia, @cooperativesmongolia
                          </a>
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </Tab.Container>
                </Container>
              </div>
            </div>
          </Layout>
        )}
      </I18n>
    )
  }
}

export default withI18next()(Associations)

export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
    images: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        relativeDirectory: { eq: "associations" }
      }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 284, maxHeight: 340, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
    myagmar_ochir: file(relativePath: { eq: "mctic/about/myagmar-ochir.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 284, maxHeight: 340, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
